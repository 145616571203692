@import url(//fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import 'styles/bootstrap';
@import "styles/scrollbar";

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  line-height: 29px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--light);
  color: var(--bs-gray-700);
}