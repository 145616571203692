
/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--bs-light);
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    opacity: 0.6;
    background: var(--bs-gray);
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--bs-gray-darker);
  }