.file-card {
    width: 200px;

    .file-preview {
        min-height: 150px;
    }

    .file-card-actions {
        bottom: 0;
        right: 0;
    }
}