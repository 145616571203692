.page-donate {
    position: relative;
    
    .donate-icon {
        max-height: 100px;
    }

    .donation-message {
        position: absolute;
        top: 0;
        right: 0;
    }
}