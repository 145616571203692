.app {
  text-align: center;
  
  .app-logo {
    height: 80px;
    pointer-events: none;
  }

  .app-footer {
    height: 20px;
    background-color: var(--bs-gray-700);
  }
}
